<template>
  <base-material-dialog
    :value="value"
    icon="far fa-user-edit"
    title="Person bearbeiten"
    :sub-title="id"
    color="primary"
    :actions="[ 'close' ]"
    @close="close"
    @esc="close"
  >
    <template v-if="!isMaster">
      Kein Zugriff!
    </template>

    <v-tabs
      v-else
      v-model="tab"
      grow
      icons-and-text
      background-color="grey lighten-3"
      color="primary"
      show-arrows
    >
      <v-tabs-slider />
      <v-tab href="#tab-info">
        Allgemein
        <v-icon>far fa-user</v-icon>
      </v-tab>
      <v-tab href="#tab-sport">
        Turnportal
        <v-icon>far fa-child fa-rotate-180</v-icon>
      </v-tab>
      <v-tab href="#tab-mail">
        E-Mail
        <v-icon>far fa-envelope</v-icon>
      </v-tab>
      <v-tab href="#tab-phone">
        Telefon
        <v-icon>far fa-phone</v-icon>
      </v-tab>
      <v-tab href="#tab-address">
        Adresse
        <v-icon>far fa-address-book</v-icon>
      </v-tab>
      <v-tab href="#tab-id">
        IDs
        <v-icon>far fa-fingerprint</v-icon>
      </v-tab>
      <v-tab href="#tab-acl">
        Zugriffsrechte
        <v-icon>far fa-key</v-icon>
      </v-tab>

      <v-tab-item value="tab-info">
        <v-card flat tile>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <base-edit-dialog
                :value="Person.familyName"
                :id="id"
                label="Nachname"
                @input="({ value, id }) => savefield(id, 'familyName', value)"
              >
                <v-text-field
                  :value="Person.familyName"
                  label="Nachname"
                  readonly
                />
              </base-edit-dialog>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <base-edit-dialog
                :value="Person.givenName"
                :id="id"
                label="Vorname"
                @input="({ value, id }) => savefield(id, 'givenName', value)"
              >
                <v-text-field
                  :value="Person.givenName"
                  label="Vorname"
                  readonly
                />
              </base-edit-dialog>
            </v-col>
            <v-col
              cols="12"
            >
              <base-edit-dialog-date
                :value="Person.birthDate"
                :id="id"
                label="Geburtsdatum"
                dateformat="DD.MM.YYYY"
                @input="({ value, id }) => savefield(id, 'birthDate', value, 'Date')"
              >
                <v-text-field
                  :value="Person.birthDate | dateformat('DD.MM.YYYY')"
                  label="Geburtsdatum"
                  readonly
                >
                  <template #prepend>
                    <v-icon>far fa-calendar-day</v-icon>
                  </template>
                </v-text-field>
              </base-edit-dialog-date>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-sport">
        <v-card flat tile class="mt-8">
          <v-btn
            absolute
            top
            right
            fab
            small
            @click="openturnportaladd"
          >
            <v-icon>
              fa-plus
            </v-icon>
          </v-btn>

          <v-data-table
            :items="Person.turnportal || []"
            :headers="[
              { text: 'Sportart', value: 'type' },
              { text: 'letzter Abgleich', value: 'lastCheck', align: 'center' },
              { text: 'gültig ab', value: 'validFrom', align: 'center' },
              { text: 'gültig bis', value: 'validUntil', align: 'center' },
              { text: 'Verein', value: 'club', sortable: false },
              { text: 'Notizen', value: 'notes', sortable: false },
              { text: 'gesperrt', value: 'banned' },
              { text: 'Festgeturnt', value: 'fixed' },
              { text: 'Jetzt abgleichen', value: 'check', sortable: false, align: 'center' },
              { text: 'Löschen', value: 'delete', sortable: false, align: 'center' }
            ]"
          >
            <template #item.type="{item}">
              {{ TPsport.find(t => t.id === item.type).value || item.type }}
            </template>
            <template #item.lastCheck="{item}">
              <span :inner-html.prop="item.lastCheck | dateformat('DD.MM.YYYY<br>HH:mm:ss')" />
            </template>
            <template #item.validFrom="{item}">
              {{ item.validFrom | dateformat('DD.MM.YYYY') }}
            </template>
            <template #item.validUntil="{item}">
              {{ item.validUntil | dateformat('DD.MM.YYYY') }}
            </template>
            <template #item.banned="{item}">
              {{ item.banned ? 'X' : '' }}
            </template>
            <template #item.fixed="{item}">
              {{ item.fixed ? 'X' : '' }}
            </template>
            <template #item.check="{item}">
              <v-btn
                fab
                text
                small
                @click="updateturnportal(item)"
              >
                <v-icon>far fa-sync</v-icon>
              </v-btn>
            </template>
            <template #item.delete="{item}">
              <v-btn
                fab
                text
                small
                @click="deleteturnportal(item)"
              >
                <v-icon>far fa-trash-alt</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>

        <base-material-dialog
          :value="tpadd.open"
          icon="far fa-running"
          title="Turnportal-Eintrag hinzufügen"
          color="primary"
          :actions="[ 'save', 'close' ]"
          @close="closeturnportaladd"
          @esc="closeturnportaladd"
          @save="saveturnportaladd"
        >
          <v-select
            v-model="tpadd.type"
            :items="TPsport"
            item-value="id"
            item-text="value"
            label="Sportart auswählen"
          />
        </base-material-dialog>
      </v-tab-item>
      <v-tab-item value="tab-mail">
        <v-card flat tile class="mt-8">
          <v-btn
            absolute
            top
            right
            fab
            small
            @click="openemailadd"
          >
            <v-icon>
              fa-plus
            </v-icon>
          </v-btn>

          <v-data-table
            :items="Person.email || []"
            :headers="[
              { text: 'Typ', value: 'type' },
              { text: 'E-Mail-Adresse', value: 'value' },
              { text: 'Löschen', value: 'delete', sortable: false, align: 'center' }
            ]"
          >
            <template #item.type="{item}">
              {{ EmailType.find(t => t.id === item.type).value }}
            </template>
            <template #item.delete="{item}">
              <v-btn
                fab
                text
                small
                @click="deleteemail(item)"
              >
                <v-icon>far fa-trash-alt</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>

        <base-material-dialog
          :value="emailadd.open"
          icon="far fa-envelope"
          title="E-Mail hinzufügen"
          color="primary"
          :actions="[ 'save', 'close' ]"
          @close="closeemailadd"
          @esc="closeemailadd"
          @save="saveemailadd"
        >
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="emailadd.type"
                :items="EmailType"
                item-value="id"
                item-text="value"
                label="Typ auswählen"
              />
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="emailadd.value"
                label="E-Mail-Adresse"
              />
            </v-col>
          </v-row>
        </base-material-dialog>
      </v-tab-item>
      <v-tab-item value="tab-phone">
        <v-card flat tile class="mt-8">
          <v-btn
            absolute
            top
            right
            fab
            small
            @click="opentelephoneadd"
          >
            <v-icon>
              fa-plus
            </v-icon>
          </v-btn>

          <v-data-table
            :items="Person.telephone || []"
            :headers="[
              { text: 'Typ', value: 'type' },
              { text: 'Telefonnummer', value: 'value.DIN' },
              { text: 'Löschen', value: 'delete', sortable: false, align: 'center' }
            ]"
          >
            <template #item.type="{item}">
              {{ PhoneType.find(t => t.id === item.type).value }}
            </template>
            <template #item.delete="{item}">
              <v-btn
                fab
                text
                small
                @click="deletetelephone(item)"
              >
                <v-icon>far fa-trash-alt</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>

        <base-material-dialog
          :value="telephoneadd.open"
          icon="far fa-phone"
          title="E-Mail hinzufügen"
          color="primary"
          :actions="[ 'save', 'close' ]"
          @close="closetelephoneadd"
          @esc="closetelephoneadd"
          @save="savetelephoneadd"
        >
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="telephoneadd.type"
                :items="PhoneType"
                item-value="id"
                item-text="value"
                label="Typ auswählen"
              />
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="telephoneadd.value"
                label="Telefonnummer"
              />
            </v-col>
          </v-row>
        </base-material-dialog>
      </v-tab-item>
      <v-tab-item value="tab-address">
        <v-card flat tile class="mt-8">
          <v-btn
            absolute
            top
            right
            fab
            small
            @click="openaddressadd"
          >
            <v-icon>
              fa-plus
            </v-icon>
          </v-btn>

          <v-data-table
            :items="Person.address || []"
            :headers="[
              { text: 'Typ', value: 'type' },
              { text: 'Straße', value: 'value.streetAddress' },
              { text: 'Hausnummer', value: 'value.streetNumber' },
              { text: 'PLZ', value: 'value.postalCode' },
              { text: 'Ort', value: 'value.addressLocality' },
              { text: 'Löschen', value: 'delete', sortable: false, align: 'center' }
            ]"
          >
            <template #item.type="{item}">
              {{ AddressType.find(t => t.id === item.type).value }}
            </template>
            <template #item.delete="{item}">
              <v-btn
                fab
                text
                small
                @click="deleteaddress(item)"
              >
                <v-icon>far fa-trash-alt</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>

        <base-material-dialog
          :value="addressadd.open"
          icon="far fa-phone"
          title="Adresse hinzufügen"
          color="primary"
          :actions="[ 'save', 'close' ]"
          @close="closeaddressadd"
          @esc="closeaddressadd"
          @save="saveaddressadd"
        >
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="addressadd.type"
                :items="AddressType"
                item-value="id"
                item-text="value"
                label="Typ auswählen"
              />
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="addressadd.value.streetAddress"
                label="Straße"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="addressadd.value.streetNumber"
                label="Hausnummer"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="addressadd.value.postalCode"
                label="PLZ"
              />
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="addressadd.value.addressLocality"
                label="Ort"
              />
            </v-col>
          </v-row>
        </base-material-dialog>
      </v-tab-item>
      <v-tab-item value="tab-id">
        <identifiers
          collection="Person"
          :identifiers="Person.identifiers || []"
          :id="id"
          value=""
        />
      </v-tab-item>
      <v-tab-item value="tab-acl">
        <v-card flat tile>
          <v-data-table
            :items="Person.acl || []"
            :headers="[
              { text: 'Typ', value: 'type' },
              { text: 'für', value: 'id' }
            ]"
          >
            <template #item.type="{item}">
              {{ AclType.find(t => t.id === item.type).value }}
            </template>
            <template #item.id="{item}">
              <template v-if="item.type === 'club'">
                {{ item.club.name }}
              </template>
              <template v-else-if="item.type === 'team'">
                {{ item.team.name }}
              </template>
              <template v-else-if="item.type === 'edv'">
                {{ item.club.name }}
              </template>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </base-material-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import gql from 'graphql-tag'
import Vue from 'vue'
import { useGraphQL } from '@/plugins/graphql'
import { AclType, AddressType, EmailType, PhoneType, TPsport } from '@/enum'

const query = `
  _id
  familyName
  givenName
  birthDate
  identifiers { name value }
  turnportal { type lastCheck validFrom validUntil club notes banned fixed }
  email { type value }
  telephone { type value { DIN } }
  address { type value { streetAddress streetNumber postalCode addressLocality addressCountry } }
  acl { type id startDate endDate club { name } team { name } }
`

export default {
  name: 'person',

  components: {
    Identifiers: () => import('@/views/components/core/components/identifiers')
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    Person: {},
    tab: 'tab-info',
    tpadd: {
      open: false,
      type: null
    },
    emailadd: {
      open: false,
      type: null,
      value: ''
    },
    telephoneadd: {
      open: false,
      type: null,
      value: ''
    },
    addressadd: {
      open: false,
      type: null,
      value: {
        streetAddress: '',
        streetNumber: '',
        postalCode: '',
        addressLocality: '',
        addressCountry: 'DE'
      }
    }
  }),

  computed: {
    ...mapGetters(['isMaster']),
    TPsport () {
      return TPsport
    },
    EmailType () {
      return EmailType
    },
    PhoneType () {
      return PhoneType
    },
    AddressType () {
      return AddressType
    },
    AclType () {
      return AclType
    }
  },

  methods: {
    close () {
      this.$emit('input', false)
    },
    savefield (id, field, value, type) {
      if (!id) {
        Vue.set(this.Person, field, value)
        return
      }

      if (!type) type = 'String'

      const q = field[0].toUpperCase() + field.substring(1).toLowerCase()

      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $${field}: ${type}!) {
            PersonUpdate${q}(id: $id, ${field}: $${field}) { _id }
          }
        `,
        variables: {
          id,
          [field]: value
        }
      })
    },
    openturnportaladd () {
      this.tpadd.open = true
      this.tpadd.type = null
    },
    closeturnportaladd () {
      this.tpadd.open = false
      this.tpadd.type = null
    },
    saveturnportaladd () {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $type: TPSport!) {
            PersonUpdateTurnportalAdd(id: $id, type: $type) { _id }
          }
        `,
        variables: {
          id: this.id,
          type: this.tpadd.type
        }
      })

      this.closeturnportaladd()
    },
    deleteturnportal (item) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $type: TPSport!) {
            PersonUpdateTurnportalDelete(id: $id, type: $type) { _id }
          }
        `,
        variables: {
          id: this.id,
          type: item.type
        }
      })
    },
    updateturnportal (item) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $type: TPSport!) {
            PersonUpdateTurnportalUpdate(id: $id, type: $type) { _id }
          }
        `,
        variables: {
          id: this.id,
          type: item.type
        }
      })
    },
    openemailadd () {
      this.emailadd.open = true
      this.emailadd.type = null
      this.emailadd.value = ''
    },
    closeemailadd () {
      this.emailadd.open = false
      this.emailadd.type = null
      this.emailadd.value = ''
    },
    saveemailadd () {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $type: EmailType!, $value: EmailAddress!) {
            PersonUpdateEmailAdd(id: $id, type: $type, value: $value) { _id }
          }
        `,
        variables: {
          id: this.id,
          type: this.emailadd.type,
          value: this.emailadd.value
        }
      }).then(() => {
        this.closeemailadd()
      }).catch(err => {
        this.$store.commit('OPEN_SNACKBAR', err)
      })
    },
    deleteemail (item) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $value: EmailAddress!) {
            PersonUpdateEmailDelete(id: $id, value: $value) { _id }
          }
        `,
        variables: {
          id: this.id,
          value: item.value
        }
      })
    },
    opentelephoneadd () {
      this.telephoneadd.open = true
      this.telephoneadd.type = null
      this.telephoneadd.value = ''
    },
    closetelephoneadd () {
      this.telephoneadd.open = false
      this.telephoneadd.type = null
      this.telephoneadd.value = ''
    },
    savetelephoneadd () {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $type: PhoneType!, $value: String!) {
            PersonUpdateTelephoneAdd(id: $id, type: $type, value: $value) { _id }
          }
        `,
        variables: {
          id: this.id,
          type: this.telephoneadd.type,
          value: this.telephoneadd.value
        }
      }).then(() => {
        this.closetelephoneadd()
      }).catch(err => {
        this.$store.commit('OPEN_SNACKBAR', err)
      })
    },
    deletetelephone (item) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $value: String!) {
            PersonUpdateTelephoneDelete(id: $id, value: $value) { _id }
          }
        `,
        variables: {
          id: this.id,
          value: item.value.DIN
        }
      })
    },
    openaddressadd () {
      this.addressadd.open = true
      this.addressadd.type = null
      this.addressadd.value.streetAddress = ''
      this.addressadd.value.streetNumber = ''
      this.addressadd.value.postalCode = ''
      this.addressadd.value.addressLocality = ''
    },
    closeaddressadd () {
      this.addressadd.open = false
      this.addressadd.type = null
      this.addressadd.value.streetAddress = ''
      this.addressadd.value.streetNumber = ''
      this.addressadd.value.postalCode = ''
      this.addressadd.value.addressLocality = ''
    },
    saveaddressadd () {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $type: AddressType!, $streetAddress: String!, $streetNumber: String!, $postalCode: String, $addressLocality: String!, $addressCountry: String!) {
            PersonUpdateAddressAdd(id: $id, type: $type, streetAddress: $streetAddress, streetNumber: $streetNumber, postalCode: $postalCode, addressLocality: $addressLocality, addressCountry: $addressCountry) { _id }
          }
        `,
        variables: {
          id: this.id,
          type: this.addressadd.type,
          streetAddress: this.addressadd.value.streetAddress,
          streetNumber: this.addressadd.value.streetNumber,
          postalCode: this.addressadd.value.postalCode,
          addressLocality: this.addressadd.value.addressLocality,
          addressCountry: this.addressadd.value.addressCountry
        }
      }).catch(err => {
        this.$store.commit('OPEN_SNACKBAR', err)
      }).then(() => {
        this.closeaddressadd()
      })
    },
    deleteaddress (item) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $streetAddress: String!, $streetNumber: String!) {
            PersonUpdateAddressDelete(id: $id, streetAddress: $streetAddress, streetNumber: $streetNumber) { _id }
          }
        `,
        variables: {
          id: this.id,
          streetAddress: item.value.streetAddress,
          streetNumber: item.value.streetNumber
        }
      })
    }
  },

  watch: {
    value () {
      this.Event = {}
    }
  },

  apollo: {
    Person: {
      query: gql`
        query($id: UUID!) { Person(id: $id) { ${query} } }
      `,
      variables () {
        return { id: this.id }
      },
      skip () {
        return !this.id || this.id === ''
      },
      subscribeToMore: {
        document: gql`
          subscription($id: UUID!) { PersonUpdate(id: $id) { ${query} } }
        `,
        variables () {
          return { id: this.id }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
